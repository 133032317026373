<template>
    <div class="type_stock">
        <div class="type_stock_header">
            <title-com title="回收类别现存存量"></title-com>
        </div>
        <div id="type_stock_chart"></div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import titleCom from '../components/block.title.vue'
export default {
    inject: ['baseUrl'],
    components: {
        titleCom
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
        });
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'stockStat'
            this.$http.post(url).then(res => {
                let { detail } = res
                this.draw(detail.recycleList)
            })
        },
        draw(dl) {
            const dom = document.querySelector("#type_stock_chart");
            const eIns = Echarts.init(dom);
            const vl = dl.map(one => one.sum)
            const xl = dl.map(one => one.recycle.cName)
            const option = {
                grid: {
                    top: "20",
                    bottom: "30",
                    left: "60",
                    right: "40"
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        color: '#fff'
                    },
                    data: xl
                },
                yAxis: {
                    type: "value",
                    show: true,
                    axisLabel: {
                        color: '#fff'
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: ["#1c345b", "rgba(0,0,0,0)"]
                        }
                    }
                },
                series: [{
                    data: vl,
                    type: "line",
                    areaStyle: {
                        color: "#4CC9FF",
                        opacity: 0.4
                    },
                    smooth: true
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
.type_stock {
    display: flex;
    padding: .8vw;
    flex-direction: column;

    .type_stock_header {
        display: flex;
        justify-content: space-between;
        color: #527db3;
        line-height: 19px;

        .type_stock_header_left::before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 16px;
            background-color: #527db3;
            vertical-align: middle;
            margin-right: 2px;
        }
    }

    #type_stock_chart {
        flex: 1;
    }
}
</style>