<template>
    <div class="collection" v-on:click="getData">
        <div class="collection_top">
            <div class="collection_top_row1">
                <div>
                    <div>今日固废收集</div>
                    <div>
                        <span v-if="wasteToday[0]!==undefined">
                            <span class="digital_number">{{wasteToday[0] | kgToT | toFixed}}</span>
                            <span>吨</span></span>
                        <span v-if="wasteToday[1]!==undefined">
                            <span class="digital_number">{{wasteToday[1] | toFixed}}</span>
                            <span>方</span>
                        </span>
                    </div>
                </div>
                <cross-line></cross-line>
                <div>
                    <div>当前年度收集</div>
                    <div>
                        <span v-if="wasteToday[0]!==undefined">
                            <span class="digital_number">{{wasteToYear[0] | kgToT | toFixed}}</span>
                            <span>吨</span>
                        </span>
                        <span v-if="wasteToday[1]!==undefined">
                            <span class="digital_number">{{wasteToYear[1] | toFixed}}</span>
                            <span>方</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="collection_top_row2">
                <div>
                    <div>运输车次</div>
                    <div>
                        <span>今日</span>
                        <span class="digital_number">{{transportTimes.today}}</span>
                        <span>/</span>
                        <span>累计</span>
                        <span class="digital_number">{{transportTimes.toYear}}</span>
                    </div>
                </div>
                <cross-line></cross-line>
                <div>
                    <div>待发车</div>
                    <div>
                        <span class="digital_number">{{transportTimes.status0}}</span>
                    </div>
                </div>
                <cross-line></cross-line>
                <div>
                    <div>运输中</div>
                    <div>
                        <span class="digital_number">{{transportTimes.status1}}</span>
                    </div>
                </div>
                <cross-line></cross-line>
                <div>
                    <div>已完成</div>
                    <div>
                        <span class="digital_number">{{transportTimes.status3}}</span>
                    </div>
                </div>
                <cross-line></cross-line>
                <div>
                    <div>今日异常</div>
                    <div>
                        <span class="digital_number">{{transportTimes.statusError}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="collection_bottom">
            <div class="collection_title">
                <title-com title="每月固废收集"></title-com>
            </div>
            <div id="collection_count"></div>
        </div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import titleCom from '../components/block.title.vue'
import CrossLine from "@/components/pages/admin/screen/CrossLine.vue";
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    computed: { ...mapGetters(['screenWasterTId']) },
    filters: {
        toFixed: function(v) {
            if (v != 0) {
                return v.toFixed(2)
            } else {
                return 0
            }
        },
        kgToT: (v) => {
            if (!v) {
                return 0
            }
            return (v / 1000)
        },
    },
    data() {
        return {
            wasteToday: [0, 0],
            wasteToYear: [0, 0],
            transportTimes: {
                today: 0,
                toYear: 0,
                status0: 0,
                status1: 0,
                status3: 0,
                statusError: 0
            }
        }
    },
    watch: {
        screenWasterTId: function() {
            this.getData()
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData()
        });
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'collectTransport'
            this.$http.post(url, this.screenWasterTId).then(res => {
                let { detail } = res
                this.wasteToday = detail.wasteToday.map(one => one.wasteType.wasteTId == this.screenWasterTId ? one.sum : undefined)
                this.wasteToYear = detail.wasteToYear.map(one => one.wasteType.wasteTId == this.screenWasterTId ? one.sum : undefined)
                this.transportTimes = detail.transportTimes
                this.draw(detail.countList)
            })
        },
        draw(dl) {
            const dom = document.querySelector("#collection_count");
            const eIns = Echarts.init(dom);
            const tl = dl.map(one => one.dateStr)
            const sl = dl.map(one => one.sum)
            const option = {
                grid: {
                    top: "7%",
                    bottom: "30",
                    left: "70"
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        color: '#fff'
                    },
                    data: tl
                },
                yAxis: {
                    type: "value",
                    show: true,
                    axisLabel: {
                        color: '#fff'
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: ["#1c345b", "rgba(0,0,0,0)"]
                        }
                    }
                },
                series: [{
                    data: sl,
                    type: "line",
                    areaStyle: {
                        color: "#4CC9FF",
                        opacity: 0.4
                    },
                    smooth: true
                }]
            };
            eIns.setOption(option);
        }
    },
    components: { CrossLine, titleCom }
};
</script>
<style lang="scss" scoped>
.collection {
    height: 100%;
    padding: .8vw;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

    .collection_top {
        text-align: center;
        font-size: $screen_font_size;

        .collection_top_row1,
        .collection_top_row2 {
            display: flex;
            justify-content: space-around;

            .digital_number {
                font-family: digital;
                font-size: $screen_font_size * 2;
                font-weight: 700;
                color: #23c8ff;
                margin: 0 8px;
            }
        }

        .collection_top_row2 {
            padding-top: 1vh;
        }
    }

    .collection_bottom {
        flex: 1;
        color: #527db3;
        display: flex;
        flex-direction: column;

        .collection_title {
            display: flex;
            justify-content: space-between;
            padding: 1vh 0;

            .collection_title_left::before {
                display: inline-block;
                content: "";
                width: 2px;
                height: 16px;
                background-color: #527db3;
                vertical-align: middle;
                margin-right: 2px;
            }
        }

        #collection_count {
            flex: 1;
        }
    }
}
</style>