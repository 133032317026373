<template>
    <div class="map_wrapper">
        <div class="overview" v-if="overviewList.length">
            <h3>概览</h3>
            <div v-for="o in overviewList" :key="o.name" class="overview-item">
                <span>{{ o.name }}</span>
                <span>{{ o.value }}{{ o.unit }}</span>
            </div>
        </div>
        <div class="map" id="centerMap"></div>
    </div>
</template>
<script>
import styleJson from "@/assets/map/custom_map_config.json";
export default {
    inject: ['baseUrl'],
    data() {
        return {
            overviewList: []
        };
    },
    mounted() {
        this.draw()
        this.getData()
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'countMsgCom'
            this.$http.post(url).then(res => {
                this.formatData(res.detail)
            })
        },
        formatData(detail){
            this.overviewList = [{
                    name: "产废企业",
                    value: detail.unit0,
                    unit: "个"
                },
                {
                    name: "分拣中心",
                    value: detail.unit3,
                    unit: "个"
                },
                {
                    name: "固废利用单位",
                    value: detail.unit1,
                    unit: "个"
                },
                {
                    name: "处置单位",
                    value: detail.unit2,
                    unit: "个"
                },
                {
                    name: "运输车辆",
                    value: detail.vehicle,
                    unit: "辆"
                },
                {
                    name: "运输司机",
                    value: detail.driver,
                    unit: "人"
                },
                {
                    name: "节能减排",
                    value: "21112",
                    unit: "kg"
                }
            ]
        },
        draw() {
            var map = new BMap.Map("centerMap"); // 创建地图实例
            var point = new BMap.Point(118.60036234, 24.90165238); // 创建点坐标
            map.centerAndZoom(point, 15);
            map.setMapStyleV2({ styleJson: styleJson });
        }
    }
};
</script>
<style lang="scss" scoped>
.map_wrapper {
    height: 100%;
    position: relative;
    font-size: $screen_font_size;

    .overview {
        position: absolute;
        height: 21vh;
        width: 9vw;
        background: rgba(16, 43, 83, 0.9);
        border: 1px solid #00ffff;
        border-radius: 5px;
        z-index: 99;
        margin-left: 52px;
        margin-top: 34px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 10px;

        .overview-item {
            span:nth-child(2) {
                float: right;
            }
        }
    }

    .map {
        height: 100%;
        width: 100%;
    }
}
</style>