<template>
    <div class="rubbish_unit" ref="ten" v-on:click="getData">
        <div class="rubbish_unit_top" ref="top">
            <div class="count_item">
                <span>
                    今日委托(取消/正常)
                    <br />
                    订单数
                </span>
                <span>
                    <span class="digital_num">{{mainData.orderWasterTodayCancel}}/{{mainData.orderWasterToday}}</span>
                    <span>单</span>
                </span>
            </div>
            <div class="count_item">
                <span>
                    累计委托
                    <br />
                    订单数
                </span>
                <span>
                    <span class="digital_num">{{mainData.orderWasterYear}}</span>
                    <span>单</span>
                </span>
            </div>
            <div class="count_item">
                <span>
                    委托订单
                    <br />
                    待派单
                </span>
                <span>
                    <span class="digital_num">{{mainData.orderWasterStart}}</span>
                    <span>单</span>
                </span>
            </div>
        </div>
        <div class="rubbish_unit_title" ref="title">
            <title-com title="企业产废统计排名"></title-com>
            <select placeholder="所属行业" v-model="industry" popper-class="select_class" popper-append-to-body="false">
                <option class="select_option" v-for="ind in industryList" :key="ind.iValue">{{ ind.iValue }}</option>
            </select>
        </div>
        <div class="table_box" :style="{'height':th+'px'}">
            <div>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>企业名称</td>
                            <td>产废量</td>
                            <td>去年同期</td>
                            <td>产废利用率</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(one,index) in dataList" v-bind:key="one.wOrderId">
                            <td>{{index+1}}</td>
                            <td>{{one.unitNa}}</td>
                            <td>{{one.sum | kgToT}} 吨</td>
                            <td>{{one.sumPre | kgToT}} 吨</td>
                            <td>{{one.percent | toPercent}} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import titleCom from '../components/block.title.vue'
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    data() {
        return {
            industry: "全部",
            industryList: [],
            mainData: {
                orderWasterToday: 0,
                orderWasterYear: 0,
                orderSchedule: 0
            },
            dataList: [],
            th: 0
        };
    },
    computed:{
        ...mapGetters(['screenWasterTId'])
    },
    watch: {
        industry: function() {
            this.getData()
        },
        screenWasterTId:function(){
            this.getData()
        }
    },
    components: {
        titleCom
    },
    mounted() {
        this.initDicts()
        this.$nextTick(() => {
            this.setStyle()
            this.getData()
        })
    },
    filters: {
        kgToT: (v) => {
            if (!v) {
                return 0
            }
            return (v / 1000).toFixed(2)
        },
        toPercent(v) {
            return (v * 100).toFixed(2)
        }
    },
    methods: {
        initDicts() {
            this.$http.post("/dict/getDictValuesByDictId", "dictindustry").then(res => {
                this.industryList = res.detail;
                this.industryList.unshift({ iValue: '全部' })
            });
        },
        getData() {
            let url = this.baseUrl + 'unitWastes'
            let params = {
                wasteT: this.screenWasterTId,
                industry: this.industry == '全部' ? '' : this.industry
            }
            this.$http.post(url, params).then(res => {
                let { detail } = res
                this.mainData = detail
                this.dataList = detail.wasteList.map(one => {
                    let obj = {
                        ...one
                    }
                    if (one.unitInfo) {
                        obj.unitNa = one.unitInfo.cName
                    }
                    return obj
                })
            })
        },
        setStyle() {
            let cw = window.innerWidth
            let t = this.$refs.ten.clientHeight
            let to = this.$refs.top.clientHeight
            let ti = this.$refs.title.clientHeight
            this.th = t - to - ti - (cw / 100) * 0.8 * 2
        },
    },
};
</script>
<style lang="scss" scoped>
.rubbish_unit {
    padding: .8vw;
    height: 100%;

    .rubbish_unit_top {
        display: flex;
        justify-content: space-around;

        .count_item {
            font-size: $screen_font_size;

            .digital_num {
                font-family: digital;
                font-weight: 700;
                font-size: 1.2vw;
                color: #24c8ff;
            }
        }
    }

    .rubbish_unit_title {
        display: flex;
        justify-content: space-between;
        color: #527db3;
        padding: 1vh 0;

        select {
            z-index: 999;
            background-color: transparent;
            color: #fff;
            border-color: #fff;
        }
    }

    .select_option{
        background-color:rgba(0, 0, 0, .3);
    }

    .table_box {
        overflow: hidden;

        >div {
            height: 100%;
            overflow-y: scroll;
            margin-right: -1vh;
        }

        table {
            width: 100%;
            text-align: center;
            font-size: $screen_font_size;

            thead {
                color: #23c8ff;
                font-weight: 700;
                background-color: #1c345b;
            }

            tbody {
                tr {
                    &:nth-child(even) {
                        background-color: #1c345b;
                    }
                }
            }

            td {
                padding: .6vh 0;

                &:nth-of-type(1) {
                    padding-left: .4vw;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.select_class {
    background-color: rgba(0, 0, 0, .5);
}
</style>