<template>
    <div class="handle" v-on:click="getData">
        <div class="handle_header">
            <title-com title="固废处置总量"></title-com>
        </div>
        <div class="recycle_total">
            <circle-text :width="6" title="今日处置" :value="today" class="circle circle1"></circle-text>
            <circle-text :width="6" title="今年处置" :value="toYear" class="circle circle2"></circle-text>
            <circle-text :width="6" title="累计处置" :value="total" class="circle circle3"></circle-text>
        </div>
        <div class="handle_header">
            <title-com title="每月固废回收/处置比例"></title-com>
        </div>
        <div id="recycle_ratio"></div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import CircleText from "../components/CircleText.vue";
import titleCom from '../components/block.title.vue'
import { mapGetters } from 'vuex'

export default {
    inject: ['baseUrl'],
    computed: {
        ...mapGetters(['screenWasterTId'])
    },
    data() {
        return {
            list: [],
            today: [0, 0, 0],
            toYear: [0, 0, 0],
            total: [0, 0]
        };
    },
    watch: {
        screenWasterTId:function(){
            this.getData()
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
        });
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'dealStat'
            this.$http.post(url, this.screenWasterTId).then(res => {
                console.log(res.detail)
                let { detail } = res
                this.today = detail.today.sort((a, b) => {
                    return a.wasteType.code - b.wasteType.code
                }).map(one => one.sum)
                this.today.push(detail.today[0].percent)
                this.toYear = detail.toYear.sort((a, b) => {
                    return a.wasteType.code - b.wasteType.code
                }).map(one => one.sum)
                this.today.push(detail.toYear[0].percent)
                this.total = detail.total.sort((a, b) => {
                    return a.wasteType.code - b.wasteType.code
                }).map(one => one.sum)
                this.drawRatio(detail.listData)
            })
        },
        drawRatio(dl) {
            const dom = document.querySelector("#recycle_ratio");
            const eIns = Echarts.init(dom);
            const dateList = dl.map(one => one.dateStr)
            const dealList = dl.map(one => one.deal)
            const stockList = dl.map(one => one.waste)
            const percentList = dl.map(one => one.percent)
            const option = {
                colors: ['#5470c6', '#91cc75', '#fac858', '#ee6666'],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                legend: {
                    right: 10,
                    top: -5,
                    textStyle:{
                        color:'#fff'
                    }
                },
                grid: {
                    top: "30",
                    bottom: "30",
                    left: "70",
                    right: "40"
                },
                xAxis: {
                    type: "category",
                    data: dateList,
                    axisLabel:{
                        color:'#fff'
                    }
                },
                yAxis: [{
                    type: "value",
                    show: true,
                    axisLabel:{
                        color:'#fff'
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                    }
                },{
                    name:'',
                    type:'value',
                    splitLine: {
                        show: false
                    },
                    axisLabel:{
                        color:'#fff'
                    }
                }],
                series: [{
                        name: "清运量",
                        type: "bar",
                        data: stockList
                    },
                    {
                        name: "处置量",
                        type: "bar",
                        data: dealList
                    },
                    {
                        name: "处置比例",
                        yAxisIndex:1,
                        type: "line",
                        data: percentList
                    }
                ]
            };
            eIns.setOption(option);
        }
    },
    components: { CircleText, titleCom }
};
</script>
<style lang="scss" scoped>
.handle {
    padding:.8vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .handle_header {
        display: flex;
        justify-content: space-between;
        color: #527db3;
        line-height: 19px;

        .handle_header_left::before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 16px;
            background-color: #527db3;
            vertical-align: middle;
            margin-right: 2px;
        }
    }

    .recycle_total {
        padding-bottom: 1vh;
        height: 6vw;
        position: relative;
        box-sizing: content-box;

        .circle1 {
            position: absolute;
            left: calc(50% - 8vw);
        }

        .circle2 {
            position: absolute;
            left: calc(50% - 3vw);
        }

        .circle3 {
            position: absolute;
            left: calc(50% + 2vw);
        }
    }

    #recycle_ratio {
        flex: 1;
    }
}
</style>