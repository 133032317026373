<template>
    <div class="circle-wrapper" :style="{ width: this.width + 'vw', height: this.width + 'vw' }">
        <span class="circle-content">
            <span>{{title}}</span>
            <br />
            <span class="number">{{value[0] | toFixed}}</span>
            <span>{{value[0]>10000?'万':''}}kg</span>
            <br />
            <span class="number">{{value[1] | toFixed}}</span>
            <span>{{value[1]>10000?'万':''}}方</span>
        </span>
        <br />
        <div class="circle-bottom">较昨日</div>
    </div>
</template>
<script>
export default {
    filters:{
        toFixed:function(v){
            if (v<10000) {
                return v.toFixed(0)
            }else if(v<100000){
                return (v/10000).toFixed(2)
            }else{
                return (v/10000).toFixed(1)
            }
        }
    },
    props: {
        title:{
            type:String,
            default:'title'
        },
        width: Number,
        value: {
            type:Array,
            default:()=>{
                return[0,0]
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.circle-wrapper {
    font-size: $screen_font_size;
    border: .3vw solid #23c8ff;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    text-align: center;
    box-sizing: border-box;

    .circle-content {
        display: inline-block;
        margin-top: 30px;

        .number {
            font-family: digital;
            font-weight: 700;
            font-size: $screen_font_size * 2;
            color: #24c8ff;
        }
    }

    .circle-bottom {
        position: absolute;
        bottom: -$screen_font_size;
        left: 50%;
        margin-left: -25px;
        padding: .2vh .3vw;
        border-radius: 10px;
        background-color: #021f4b;
        border: 1px solid #007afe;
    }
}
</style>