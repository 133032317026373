<template>
    <div class="rank">
        <div class="rank_left">
            <div class="left_top">
                <div>
                    <span>当前库存量</span>
                    <br />
                    <span class="number">{{stockSum | kgToT |toFixed}}</span>
                    <span>吨</span>
                </div>
                <div>
                    <span>年度回收量</span>
                    <br />
                    <span class="number">{{yearIn | kgToT | toFixed}}</span>
                    <span>吨</span>
                </div>
                <div>
                    <span>年度利用率</span>
                    <br />
                    <span class="number">{{(useRatio * 100) | toFixed}}</span>
                    <span>%</span>
                </div>
            </div>
            <div>
                <el-row class="count-label">
                    <el-col :span="12">
                        <span class="count-label-in">入库（{{screenUnit}}）</span>
                    </el-col>
                    <el-col :span="12">
                        <span class="count-label-out">出库</span>
                    </el-col>
                </el-row>
                <el-row v-for="l in ioList" :key="l.label" class="count-value">
                    <el-col :span="12">
                        <span>{{ l.label }}：</span>
                        <span class="number">{{ l.in | kgToT(screenUnit) | toFixed}}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>{{ l.label }}：</span>
                        <span class="number">{{ l.out | toFixed}}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="rank_right">
            <div class="rank_right_header">
                <title-com title="累计回收类别排名"></title-com>
            </div>
            <div id="rank_chart"></div>
        </div>
    </div>
</template>
<script>
import titleCom from '../components/block.title.vue'
import * as Echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    computed: {
        ...mapGetters(['screenWasterTId', 'screenUnit'])
    },
    filters: {
        toFixed: function(v) {
            if (v != 0) {
                return v.toFixed(2)
            } else {
                return 0
            }
        },
        kgToT: function(v, screenUnit) {
            if (screenUnit && screenUnit == '方') {
                return v
            }
            if (!v) {
                return 0
            }
            return (v / 1000)
        },
    },
    data() {
        return {
            ioList: [{
                    label: "今日",
                    in: 0,
                    out: 0
                },
                {
                    label: "昨日",
                    in: 0,
                    out: 0
                },
                {
                    label: "本月",
                    in: 0,
                    out: 0
                },
                {
                    label: "年度",
                    in: 0,
                    out: 0
                }
            ],
            stockSum: 0,
            yearIn: 0,
            useRatio: 0
        };
    },
    components: {
        titleCom
    },
    mounted() {
        this.$nextTick(() => {
            this.getData()
        });
    },
    watch: {
        screenWasterTId: function() {
            this.getData()
        }
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'stockStat'
            this.$http.post(url, this.screenWasterTId).then(res => {
                let { detail } = res
                this.ioList = detail.ioList
                this.stockSum = detail.stockSum
                this.yearIn = detail.yearIn
                this.useRatio = detail.useRatio
                this.draw(detail.recycleList)
            })
        },
        sortRecycle(list) {
            list.sort((a, b) => {
                return b.sum - a.sum
            })
            let other = list.slice(5)
            let otherN = other.reduce(function(a, b) {
                return { sum: a.sum + b.sum }
            })
            let l = list.slice(0, 5)
            l.push({ recycle: { cName: '其它' }, sum: otherN.sum })
            return l
        },
        draw(dl) {
            dl = this.sortRecycle(dl).reverse()
            const dom = document.querySelector("#rank_chart");
            const eIns = Echarts.init(dom);
            const vl = dl.map(one => one.sum)
            const xl = dl.map(one => one.recycle.cName)
            const option = {
                title: {
                    subtext: "总量",
                    right: 15,
                    top: -15
                },
                grid: {
                    top: "10",
                    left: "70",
                    right: '60',
                    bottom: "10"
                },
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                    show: false
                },
                yAxis: [{
                        type: "category",
                        position: "left",
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            color: '#fff'
                        },
                        data: xl
                    },
                    {
                        type: "category",
                        position: "right",
                        axisLabel: {
                            color: '#fff'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        nameTextStyle: {
                            fontFamily: "digital"
                        },
                        data: vl
                    }
                ],
                series: [{
                    name: "2011",
                    type: "bar",
                    data: vl,
                    itemStyle: {
                        color: function(param) {
                            if (param.value < 110) {
                                return 'green'
                            } else if (param.value > 110) {
                                return 'red'
                            }
                        }
                    }
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
.rank {
    display: flex;
    padding: .8vw;

    .rank_left {
        width: 14vw;
        font-size: $screen_font_size;

        .left_top {
            display: flex;
            justify-content: space-around;
            padding-bottom: 2vh;
        }

        .count-label {
            color: #fff;
            text-align: center;

            .count-label-in {
                background-color: #00cf8b;
                padding: 3px 5px;
                border-radius: 5px;
            }

            .count-label-out {
                background-color: red;
                padding: 3px 5px;
                border-radius: .5vh;
            }
        }

        .count-value {
            text-align: center;
            margin-top: .6vh;
        }

        .number {
            font-family: digital;
            color: #24c8ff;
            font-weight: 700;
            font-size: $screen_font_size * 2;
        }
    }

    .rank_right {
        padding-left: 1vw;
        flex: 1;
        display: flex;
        flex-direction: column;

        #rank_chart {
            flex: 1;
        }
    }
}
</style>