<template>
    <div>
        <div id="screen1" v-if="showView">
            <bg></bg>
            <page-top>
                <div class="top_button">
                    <span v-for="one in wasteTypes" :key="one.wasteTId" :class="{'alive':one.wasteTId==wasteTId}" v-on:click="typeClick(one)">{{one.cName}}</span>
                </div>
            </page-top>
            <div class="content_view">
                <transition>
                    <component :is="current"></component>
                </transition>
            </div>
        </div>
        <img :src="screen1" style="height: 100vh;width: 100vw;" v-else />
    </div>
</template>
<script>
import screen1 from '@/assets/screen/screen1.png'
import pageTop from '../components/top.vue';
import bg from '../components/bg.vue'
import home from './index.bak.vue'
import { mapMutations } from 'vuex'

export default {
    components: {
        pageTop,
        bg,
        home
    },
    data() {
        return {
            showView: true,
            current: 'home',
            screen1,
            wasteTypes: [],
            wasteTId: ''
        };
    },
    created() {
        this.getWasteType()
    },
    methods: {
        ...mapMutations(['setScreen']),
        getWasteType() {
            this.$http.post("/wasteType/listAll").then(res => {
                let { detail } = res
                this.wasteTypes = detail
                this.wasteTId = detail[0].wasteTId
                this.setScreen({ wasteTId: detail[0].wasteTId, unit: detail[0].unit })
            })
        },
        typeClick(one) {
            this.wasteTId = one.wasteTId
            this.setScreen({ wasteTId: one.wasteTId, unit: one.unit })
        }
    }
};
</script>
<style lang="scss" scoped>
html {
    overflow: hidden;

    body {
        background: transparent;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.07vw;

        #screen1 {
            .top_button {
                span {
                    display: inline-block;
                    font-size: .7vw;
                    padding: .2vw;
                    border: 1px solid darken(#fff, 50%);
                    border-radius: .4vh;
                    margin: .2vh;
                    background-color: rgba(225, 225, 225, .2);
                    color: darken(#fff, 50%);
                    @extend .hand;

                    &.alive {
                        color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }

        .content_view {
            height: 90vh;
        }
    }
}
</style>