<template>
    <div id="common_service">
        <div class="common_service_top">
            <div class="common_service_left">
                <common-block class="common_service_item" title="产废">
                    <rubbish-unit></rubbish-unit>
                </common-block>
                <common-block class="common_service_item" title="运输">
                    <collection></collection>
                </common-block>
            </div>
            <div class="common_service_mid">
                <center-map></center-map>
            </div>
            <div class="common_service_right">
                <common-block class="common_service_item" title="固废类别分布">
                    <recycle-distribute></recycle-distribute>
                </common-block>
                <common-block class="common_service_item screen_bottom" title="处置">
                    <handle></handle>
                </common-block>
            </div>
        </div>
        <div class="common_service_bottom">
            <common-block class="common_service_item" title="分拣利用">
                <div class="service_bottom_wrapper">
                    <total-type-rank></total-type-rank>
                    <rubbish-stock></rubbish-stock>
                    <month-stock></month-stock>
                </div>
            </common-block>
        </div>
    </div>
</template>

<script>
    import CommonBlock from "@/components/pages/admin/screen/block.vue";
    import RecycleDistribute from "./RecycleDistribute.vue";
    import Handle from "./Handle.vue";
    import RubbishUnit from "./RubbishUnit.vue";
    import Collection from "./Collection.vue";
    import CenterMap from "./CenterMap.vue";
    import TotalTypeRank from "./TotalTypeRank.vue";
    import RubbishStock from "./RubbishStock.vue";
    import MonthStock from "./MonthStock.vue";
    export default {
        components: {
            CommonBlock,
            RecycleDistribute,
            Handle,
            RubbishUnit,
            Collection,
            CenterMap,
            TotalTypeRank,
            RubbishStock,
            MonthStock
        },
        data() {
            return {};
        },
        provide(){
            return{
                baseUrl:'/screen/unit/',
            }
        },
    };
</script>
<style lang="scss" scoped="common_service">
    #common_service {
        padding: 0 1em;
        height: 89vh;
        color: #fff;
        display: grid;
        grid-template-rows: 2fr 1fr;
        gap: 0.5rem;
        .common_service_top {
            display: grid;
            gap: 1em;
            grid-template-columns: 1fr 2fr 1fr;
            .common_service_left {
                flex: 1;
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                gap: 0.5em;
            }
            .common_service_mid {
            }
            .common_service_right {
                flex: 1;
                display: grid;
                grid-template-rows: 1fr 1.5fr;
                gap: 0.5em;
            }
        }
        .common_service_bottom {
            .service_bottom_wrapper {
                display: grid;
                grid-template-columns: 1.5fr 1fr 1fr;
                gap: 1rem;
                height: 100%;
            }
        }
    }
</style>
