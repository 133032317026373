<template>
    <div class="recycle">
        <div class="recycle_header">
            <title-com title="各固废类别收集量"></title-com>
            <span>（2021年1月至今）</span>
        </div>
        <div id="recycle_chart"></div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import titleCom from '../components/block.title.vue'
export default {
    inject: ['baseUrl'],
    components: {
        titleCom
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'wasteTypeStat'
            this.$http.post(url).then(res => {
                this.draw(res.detail)
            })
        },
        draw(dl) {
            console.log(dl)
            const dom = document.querySelector("#recycle_chart");
            const eIns = Echarts.init(dom);
            const total = dl.reduce((a, b) => {
                return { count: a.count + b.count }
            })
            const dataList = dl.map(one => {
                return {
                    ...one,
                    name: one.wasteType.cName,
                    value: one.count,
                    percent: ((one.count / total.count) * 100).toFixed(2)
                }
            })
            const option = {
                colors: ['#5470c6', '#91cc75', '#fac858', '#ee6666'],
                series: [{
                    name: "Access From",
                    type: "pie",
                    radius: ["40%", "70%"],
                    label: {
                        textBorderWidth:0,
                        formatter: function(params) {
                            const data = params.data;
                            return `{a|${data.name}}\n{b|${data.value}}吨/{c|${data.percent}}%\n`;
                        },
                        rich: {
                            a: {
                                fontSize: 12,
                                lineHeight: 30
                            },
                            b: {
                                fontSize: 18
                            },
                            c: {
                                fontSize: 18
                            }
                        },
                        color: '#fff'
                    },
                    // labelLine: {
                    //     show: false
                    // },
                    data: dataList
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
.recycle {
    height: 100%;
    padding: .8vw;

    .recycle_header {
        display: flex;
        justify-content: space-between;
        color: #527db3;
        line-height: 19px;

        .recycle_header_left::before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 16px;
            background-color: #527db3;
            vertical-align: middle;
            margin-right: 2px;
        }
    }

    #recycle_chart {
        height: 100%;
    }
}
</style>