<template>
    <div class="stock" v-on:click="getData">
        <div class="stock_header">
            <title-com title="每月入库统计"></title-com>
        </div>
        <div id="stock_chart"></div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import titleCom from '../components/block.title.vue'
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    computed: { ...mapGetters(['screenWasterTId']) },
    components: {
        titleCom
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
        });
    },
    watch: {
        screenWasterTId: function() {
            this.getData()
        }
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'stockInOutList'
            this.$http.post(url, this.screenWasterTId).then(res => {
                this.draw(res.detail)
            })
        },
        draw(dl) {
            const dom = document.querySelector("#stock_chart");
            const eIns = Echarts.init(dom);
            const dateList = dl.map(one => one.dateStr)
            const inList = dl.map(one => one.in)
            const option = {
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    data: ["入库"],
                    right: "10%",
                    textStyle: {
                        color: '#fff'
                    }
                },
                grid: {
                    top: "20",
                    bottom: "30",
                    left: "60"
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        color: '#fff'
                    },
                    data: dateList
                },
                yAxis: {
                    type: "value",
                    show: true,
                    axisLabel: {
                        color: '#fff'
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: ["#1c345b", "rgba(0,0,0,0)"]
                        }
                    }
                },
                series: [{
                    name: "入库",
                    type: "line",
                    // stack: "Total",
                    data: inList
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
.stock {
    display: flex;
    padding: .8vw;
    flex-direction: column;

    .stock_header {
        display: flex;
        justify-content: space-between;
        color: #527db3;
        line-height: 19px;

        .stock_header_left::before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 16px;
            background-color: #527db3;
            vertical-align: middle;
            margin-right: 2px;
        }
    }

    #stock_chart {
        flex: 1;
    }
}
</style>