<template>
  <div class="screen_block_title">
    <div class="title">{{title}}</div>
  </div>
</template>
<script>
  export default{
    props:{
      title:{
        type:String,
        default:'title'
      }
    }
  }
</script>
<style lang="scss" scoped="screen_block_title">
  $s-p:-0.05vh;
  .screen_block_title{
    position:relative;
    .title{
      font-size: .65vw;
      padding-left: 0.4vw;
      color:#527db3;
      &:before{
        content: '';
        display: inline-block;
        position: absolute;
        left:0;
        top:$s-p;
        bottom: $s-p;
        background-color: #527db3;
        width: 0.15vw;
      }
    }
  }
</style>